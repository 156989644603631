import { __assign, __rest } from "tslib";
import * as React from 'react';
import { RemoveScroll } from 'react-remove-scroll/UI';
import ReactFocusLock from 'react-focus-lock/UI';
import { effectCar } from './medium';
var PREVENT_SCROLL = { preventScroll: true };
export var FocusOn = React.forwardRef(function (props, parentRef) {
    var _a = React.useState(false), lockProps = _a[0], setLockProps = _a[1];
    var children = props.children, autoFocus = props.autoFocus, shards = props.shards, crossFrame = props.crossFrame, _b = props.enabled, enabled = _b === void 0 ? true : _b, _c = props.scrollLock, scrollLock = _c === void 0 ? true : _c, _d = props.focusLock, focusLock = _d === void 0 ? true : _d, _e = props.returnFocus, returnFocus = _e === void 0 ? true : _e, inert = props.inert, allowPinchZoom = props.allowPinchZoom, sideCar = props.sideCar, className = props.className, shouldIgnore = props.shouldIgnore, preventScrollOnFocus = props.preventScrollOnFocus, style = props.style, as = props.as, gapMode = props.gapMode, rest = __rest(props, ["children", "autoFocus", "shards", "crossFrame", "enabled", "scrollLock", "focusLock", "returnFocus", "inert", "allowPinchZoom", "sideCar", "className", "shouldIgnore", "preventScrollOnFocus", "style", "as", "gapMode"]);
    var SideCar = sideCar;
    var onActivation = lockProps.onActivation, onDeactivation = lockProps.onDeactivation, restProps = __rest(lockProps, ["onActivation", "onDeactivation"]);
    var appliedLockProps = __assign(__assign({}, restProps), { as: as,
        style: style,
        sideCar: sideCar,
        shards: shards,
        allowPinchZoom: allowPinchZoom,
        gapMode: gapMode,
        inert: inert, enabled: enabled && scrollLock });
    return (React.createElement(React.Fragment, null,
        React.createElement(ReactFocusLock, { ref: parentRef, sideCar: sideCar, disabled: !(lockProps && enabled && focusLock), returnFocus: returnFocus, autoFocus: autoFocus, shards: shards, crossFrame: crossFrame, onActivation: onActivation, onDeactivation: onDeactivation, className: className, whiteList: shouldIgnore, lockProps: appliedLockProps, focusOptions: preventScrollOnFocus ? PREVENT_SCROLL : undefined, as: RemoveScroll }, children),
        enabled && (React.createElement(SideCar, __assign({}, rest, { sideCar: effectCar, setLockProps: setLockProps, shards: shards })))));
});
export * from './reExports';
