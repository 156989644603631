'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

exports.default = polylinearScale;

var _piecewise = require('./piecewise');

var _piecewise2 = _interopRequireDefault(_piecewise);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function polylinearScale(stops) {
  var xs = [];
  var ys_r = [];
  var ys_g = [];
  var ys_b = [];

  stops.forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        domain = _ref2[0],
        range = _ref2[1];

    xs.push(domain);
    if (isNaN(range)) {
      ys_r.push(parseInt(range.substr(1, 2), 16));
      ys_g.push(parseInt(range.substr(3, 2), 16));
      ys_b.push(parseInt(range.substr(5, 2), 16));
    } else {
      ys_r.push(range);
    }
  });

  var pw_r = (0, _piecewise2.default)(xs, ys_r);

  if (ys_g.length && ys_b.length) {
    var _ret = function () {
      var pw_g = (0, _piecewise2.default)(xs, ys_g);
      var pw_b = (0, _piecewise2.default)(xs, ys_b);
      return {
        v: function v(x) {
          return 'rgb(' + Math.round(pw_r(x)) + ', ' + Math.round(pw_g(x)) + ', ' + Math.round(pw_b(x)) + ')';
        }
      };
    }();

    if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
  } else {
    return function (x) {
      return pw_r(x);
    };
  }
}
module.exports = exports['default'];