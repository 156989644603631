"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = piecewise;
function piecewise(xs, ys) {
  return function (x) {
    // out of bounds
    if (x <= xs[0]) {
      return ys[0];
    } else if (x >= xs[xs.length - 1]) {
      return ys[xs.length - 1];
    }

    // bisect
    var lo = 0;
    var hi = xs.length - 1;

    while (hi - lo > 1) {
      var mid = lo + hi >> 1;
      if (x < xs[mid]) {
        hi = mid;
      } else {
        lo = mid;
      }
    }

    // project
    return ys[lo] + (ys[hi] - ys[lo]) / (xs[hi] - xs[lo]) * (x - xs[lo]);
  };
}
module.exports = exports["default"];