'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _polylinearScale = require('./polylinear-scale');

var _polylinearScale2 = _interopRequireDefault(_polylinearScale);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transformUnits = {
  perspective: 'px',
  rotate: 'deg',
  rotateX: 'deg',
  rotateY: 'deg',
  rotateZ: 'deg',
  scale: '',
  scaleX: '',
  scaleY: '',
  scaleZ: '',
  skew: 'deg',
  skewX: 'deg',
  skewY: 'deg',
  translateX: 'px',
  translateY: 'px',
  translateZ: 'px'
};
var transformKeys = Object.keys(transformUnits);

var AnimationBus = function () {
  function AnimationBus(_ref) {
    var animations = _ref.animations,
        element = _ref.element,
        origin = _ref.origin;

    _classCallCheck(this, AnimationBus);

    this.animations = animations;
    this.element = element;
    this.origin = origin;
  }

  _createClass(AnimationBus, [{
    key: 'getStyles',
    value: function getStyles() {
      var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.element;

      var origin = this.origin(element);
      var transformValues = [];
      var styles = {};

      this.animations.forEach(function (animation) {
        var prop = animation.prop;
        var unit = animation.unit || transformUnits[prop] || '';
        var value = (0, _polylinearScale2.default)(animation.stops)(origin);

        if (transformKeys.indexOf(prop) > -1) {
          transformValues.push(prop + '(' + value + unit + ')');
        } else {
          styles[prop] = '' + value + unit;
        }
      });

      if (transformValues.length) {
        styles.transform = transformValues.join(' ');
      }

      return styles;
    }
  }, {
    key: 'applyStyles',
    value: function applyStyles() {
      var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.element;

      var styles = this.getStyles(element);
      Object.keys(styles).forEach(function (key) {
        return element.style[key] = styles[key];
      });
    }
  }]);

  return AnimationBus;
}();

exports.default = AnimationBus;
module.exports = exports['default'];